import * as React from "react"
import "../styles/app.sass"
import {TimeHelper} from "../helpers/TimeHelper";
import {useEffect, useState} from "react";
import Link from "gatsby-link";

const VideosPage = ({location}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const params = new URLSearchParams(location.search || '')
    const source = params.get('source') || 'cv2021';

    useEffect(() => {
        fetch(`/api/Courses/${source}`)
            .then(res => res.json())
            .then(response => {
                setCourses(response);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false))
    }, [source])

    return (
        <main className="app">
            <section className="l-container l-container--center">
                <Link to='/' className='button mt-6'>
                    <span className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21v-2z"/>
                        </svg>
                        <span className='ml-2'>Back to portfolio</span>
                    </span>
                </Link>
                <section className='header'>
                    <h1 className='header__title'>Courses</h1>
                    <p className='header__description'>The following video's have been created by me to practice with
                        explaining complex topics. I have taken a deep-dive on each topic to be able to explain it as
                        clear and concise as possible. As a side-effect I have gained a lot of new knowledge and can
                        pass it on using these videos.</p>
                </section>
                <section className="paper paper--rounded">
                    <div className='paper__body'>
                        {isLoading && <div>
                            <p>The video courses are being loaded.</p>
                        </div>}
                        {!isLoading && courses.length === 0 && <div>
                            <p>No content has been found for the provided source, check if the provided url is
                                correct.</p>
                        </div>}
                        {courses.map((course, index) => {
                            return <div className={`course ${index === 0 ? 'course--first' : ''}`}>
                                <h2 className='course__title'>{course.name}</h2>
                                <p className='course__description'>{course.description}</p>

                                <div className='course__videos'>
                                    {course.videos.map(video => {
                                        return <div className='video-card'>
                                            <div className='video-card__image-placeholder'
                                                 style={{backgroundImage: `url(${video.placeholderUrl})`}}>

                                            </div>
                                            <div className='video-card__body'>
                                                <span className='video-card__duration'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                         viewBox="0 0 24 24" width="24px"><path
                                                        d="M0 0h24v24H0V0z" fill="none"/><path
                                                        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>
                                                    <span
                                                        className='ml-2'>{TimeHelper.formatAsDuration(video.durationInMinutes)}</span>
                                                </span>
                                                <span className='video-card__title'>{video.name}</span>
                                                <p className='video-card__description'>{video.description}</p>

                                                <a className='video-card__link' target='_blank'
                                                   rel='noreferrer noopener' href={video.url}>
                                                    <span className='flex items-center justify-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                             viewBox="0 0 24 24" width="24px" fill="#000000"><path
                                                            d="M0 0h24v24H0V0z" fill="none"/><path
                                                            d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"/></svg>
                                                        <span>View video</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </section>
            </section>
        </main>
    )
}

export default VideosPage;
