export class TimeHelper {
    static formatAsDuration(durationInMinutes: number){
        const hours = Math.floor(durationInMinutes / 60)

        if(hours < 1){
            return `${durationInMinutes}m`;
        }

        const leftOverMinutes = durationInMinutes % 60
        const formattedMinutes = leftOverMinutes > 0 ? ` ${leftOverMinutes}m` : ''

        return `${hours}h ${formattedMinutes}`
    }
}